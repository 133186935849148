import React, { Component } from "react";
import { Container, Row, Col, Jumbotron, Button, Input } from "reactstrap";

import validator from "validator";
import analyticsTracker from "../../../analytics/AnalyticsTracker";

import visitor_profile from "../../VisitorProfile";
import AppointmentFormatter from "./AppointmentFormatter";
import MenuNavBar from "../../MenuNavBar";
import ContentContainer from "../../containers/ContentContainer";
import SelectMenu from "../../components/SelectMenu";
import "../Solutions.css";

class AppointmentConfirmation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emailAddress: "",
      intl_numbers: [],
      phone: "",
      formattedTime: {},
      validEmail: true,
      canSubmit: true,
      notice: "",
      emailSent: "",
    };

    this.makePhoneNumbersList = this.makePhoneNumbersList.bind(this);
    this.sendEmailConfirmation = this.sendEmailConfirmation.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.selectChange = this.selectChange.bind(this);
  }

  componentDidMount() {
    if (this.props.location.state) {
      var appointment = AppointmentFormatter(this.props.location.state.date);
      this.setState({ formattedTime: appointment });
      if (this.props.location.state.date.suggestedPhone) {
        for (
          let i = 0;
          i < this.props.location.state.date.intl_phone_numbers.length;
          i++
        ) {
          if (
            this.props.location.state.date.suggestedPhone ==
            this.props.location.state.date.intl_phone_numbers[i].phone
          ) {
            this.setState({
              phone: `${this.props.location.state.date.suggestedPhone} (${this.props.location.state.date.intl_phone_numbers[i].country_name})`,
            });
          }
        }
      } else {
        this.setState({
          phone: `${this.props.location.state.date.phone} (United States)`,
        });
      }

      if (
        this.props.location.state.date.intl_phone_numbers &&
        this.props.location.state.date.intl_phone_numbers.length > 0
      ) {
        //				this.setState({phoneNumber : ""});
        this.setState({
          intl_numbers: this.props.location.state.date.intl_phone_numbers,
        });
      }

      // save the path to the database including what time the appointment is
      visitor_profile.recordPath(
        this.props.path_id,
        JSON.stringify(appointment)
      );
    } else {
      visitor_profile.recordPath(this.props.path_id);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
    analyticsTracker.trackEvent({
      category: "User",
      action: "Reached Leaf Node",
      pathId: this.props.path_id,
    });
  }

  render() {
    const menulabel = "- Select Region -";
    let numbers = this.makePhoneNumbersList();
    
    if (this.props.location.state && this.props.location.state.date) {
      return (
        <div className="page__container">
          <MenuNavBar className="page__navbar" selected="talk" />
          <Jumbotron className="short__background" fluid>
            <Container fluid>
              <Col xs="12" sm={{ size: 10, offset: 1 }}>
                <h1 className="advice__appt_title">
                  Thanks for making an Ombuds appointment
                </h1>
                <h4 className="advice__appt_subtitle">
                  We look forward to talking with you soon
                </h4>
                <ContentContainer>
                  <div className="empty__header">&nbsp;</div>
                  <Row>
                    <Col xs="12" sm={{ size: 6, offset: 2 }}>
                      <h3>Here are your appointment details</h3>

                      <div className="advice__appt_details">
                        <div className="advice_appt_spacing">
                          <span className="text__emphasis">Date: </span>
                          {this.state.formattedTime.date}
                        </div>
                        <div>
                          <span className="text__emphasis">Time: </span>
                          {this.state.formattedTime.startTime}{" "}
                          {this.state.formattedTime.timezone}{" "}
                          {this.state.formattedTime.whichTwelve}{" "}
                          {"("}{this.state.formattedTime.showTZ}{")"}
                        </div>
                        <div>
                          <span className="text__emphasis">
                            Call your Ombuds using the following number:
                          </span>
                        </div>
                        <ul>
                          <li>
                            {this.state.phone.value
                              ? this.state.phone.value
                              : this.state.phone}
                          </li>
                        </ul>
                        {this.state.intl_numbers.length > 0 ? (
                          <div>
                            <span className="text__emphasis">
                              Need a number for a different region? Find it
                              here:
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                        {this.state.intl_numbers.length > 0 ? (
                          <div>
                            <SelectMenu
                              changeHandler={this.selectChange}
                              id="phone_numbers"
                              name="phone_numbers"
                              className={`advice_appt_spacing ${
                                this.state.phone
                                  ? "hr__select_contact_post"
                                  : ""
                              }`}
                              label={menulabel}
                              options={numbers}
                              value={this.state.phone}
                              clearable={false}
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {this.props.location.state.date.espanol && (
                          <div
                            style={{ "text-align": "left" }}
                            className="scheduler__list_language_vailability"
                          >
                            <span>*English/Español</span>
                          </div>
                        )}
                      </div>

                      <div className="advice__appt_instructions">
                        <strong>Please make note of the local timezone your appointment is scheduled in. </strong>
                        Copy and paste your appointment details into your calendar or provide an email address below so we can send the details to you. 
                      </div>
                    </Col>

                    <Col xs="12" sm={{ size: 8, offset: 2 }}>
                      <form>
                        <p>
                          Feel free to use a personal email address if you
                          prefer.
                        </p>
                        <Input
                          id="emailInput"
                          type="email"
                          valid={this.state.validEmail}
                          onChange={this.handleEmailChange}
                          value={this.state.emailAddress}
                          placeholder="Enter email address"
                        />
                        <div className="authentication__error">
                          {this.state.notice}
                          <img
                            src="/icons/warning.svg"
                            hidden={this.state.validEmail}
                            alt="invalid email address"
                            align="right"
                          />
                        </div>
                        <Button
                          type="submit"
                          className="button__primary"
                          disabled={
                            !this.state.canSubmit || !this.state.validEmail
                          }
                          onClick={this.sendEmailConfirmation}
                        >
                          Email me
                        </Button>
                      </form>
                      <p>
                        <i>{this.state.emailSent}&nbsp;</i>
                      </p>
                    </Col>

                    <Col xs="12" sm={{ size: 6, offset: 2 }}>
                      <div className="advice__appt_note">
                        <span className="text__emphasis">Note: </span>If you
                        need to cancel your appointment, please email
                        ombuds@tequitable.com or call the number above and leave
                        a message.
                      </div>
                      <div className="advice__appt_confidence">
                        Confidentiality is of utmost importance to us. Providing
                        your email is optional and entirely for your
                        convenience. We do not retain your email address or
                        create a user account for you.
                      </div>
                    </Col>
                  </Row>
                </ContentContainer>
              </Col>
            </Container>
          </Jumbotron>
        </div>
      );
    } else {
      return (
        <div>
          <MenuNavBar className="page__navbar" />
          <div>Session timed out. Please go back to the home page.</div>
        </div>
      );
    }
  }

  selectChange(selectedOption) {
    analyticsTracker.trackEvent({
      category: "User",
      action: "Selected a non-default ombuds number",
    });
    this.setState({ phone: selectedOption });
    this.setState({ canSubmit: true });
    this.setState({ emailSent: "" });
  }

  makePhoneNumbersList() {
    function SortArray(x, y) {
      if (x.country_name > y.country_name) {
        return 1;
      }
      if (x.country_name < y.country_name) {
        return -1;
      }
      return 0;
    }

    let sortedOptions = this.state.intl_numbers.sort(SortArray);

    let options = sortedOptions.map((n) => {
      return {
        label: `${n.phone} (${n.country_name ? n.country_name : ""})`,
        value: `${n.phone} (${n.country_name ? n.country_name : ""})`,
      };
    });

    return options;
  }

  sendEmailConfirmation(e) {
    e.preventDefault();

    if (this.state.emailAddress !== "") {
      analyticsTracker.trackEvent({
        category: "User",
        action: "Appointment Email Confirmation Sent",
      });

      // call to backend service that sends email confirmation for the Appointment
      fetch("/calendar/email", {
        method: "POST",
        body: JSON.stringify({
          email_address: this.state.emailAddress,
          appointment_time: this.state.formattedTime,
          appointment_phone: this.state.phone.value
            ? this.state.phone.value
            : this.state.phone,
          intl_phones: this.props.location.state.date.intl_phone_numbers,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + visitor_profile.getJwt(),
          "tEQ-preauthorized": visitor_profile.getPreauthorized(),
        },
      })

		.then((res) => {
			return res.json();
		})
        .then((res) => {
          if (res.error) {
			this.setState({emailSent : "Apologies, something went wrong. Please try again."});
            analyticsTracker.trackEvent({
              category: "User",
              action: res.error,
            });
          } else {
            this.setState({ emailSent: "Sent!" });
          }
        });
    }
  }

  handleEmailChange(e) {
    this.setState({ emailAddress: e.target.value });

    let valid = true;
    let canSubmit = true;
    // If we haven't yet typed an @ then don't warn yet
    // but don't let them click submit
    if (!e.target.value.includes("@")) {
      valid = true;
      canSubmit = false;
    } else if (!validator.isEmail(e.target.value)) {
      valid = false;
      canSubmit = false;
    }

    this.setState({ validEmail: valid });
    this.setState({ canSubmit: canSubmit });

    if (!valid) {
      this.setState({ notice: "Please enter a valid email address" });
    } else {
      this.setState({ notice: "" });
    }
  }
}

export default AppointmentConfirmation;