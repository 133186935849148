import React, { Component } from 'react';
import StoryInputStepOne from './StoryInputStepOne';
import StoryInputStepTwo from './StoryInputStepTwo';
import { Jumbotron, Container, Row, Col, Button, Modal, ModalBody } from 'reactstrap';
import ContentContainer from './containers/ContentContainer';
import ScrollToTopOnMount from './components/ScrollToTopOnMount';
import MenuNavBar from './MenuNavBar';
import analyticsTracker from '../analytics/AnalyticsTracker';
import visitor_profile from './VisitorProfile';

class StoryInput extends Component {
  constructor(props) {
    super(props);
  this.state = {
    modal: false,
    modalUrl: '/outcome',
    modalBody: null
  }
  this.checkIfShowModal = this.checkIfShowModal.bind(this)
  this.toggleModal = this.toggleModal.bind(this)
  this.updateState = this.updateState.bind(this)
  this.updateText = this.updateText.bind(this)
  this.setStoryData = this.setStoryData.bind(this)
  this.dontSaveStory = this.dontSaveStory.bind(this)
  this.saveStory = this.saveStory.bind(this)
  this.saveDelay = 1000;
  this.timeOutId = null;
  // console.log(visitor_profile.ifFeatureEnabled('PRD-526-variant-b'));
}

  componentDidMount() {

    visitor_profile.recordPath(this.props.path_id);
  }

  componentDidUpdate(prevProps, prevState) {
    
    if (this.state.selectedPerspective !== prevState.selectedPerspective ||this.state.selectedBehavior !== prevState.selectedBehavior ||this.state.selectedRelationship !== prevState.selectedRelationship ||this.state.selectedLevel !== prevState.selectedLevel || this.state.selectedDepartment !== prevState.selectedDepartment || this.state.selectedOffice !== prevState.selectedPerspective || this.state.selectedPerspective !== prevState.selectedPerspective|| this.state.selectedIdentities !== prevState.selectedIdentities) {
      const identityValues = this.state.selectedIdentities ? this.state.selectedIdentities.map(identities => identities.value) : '';

      if (this.timeOutId) {
        clearTimeout(this.timeOutId);
      }

      this.timeOutId = setTimeout(()=>{
        visitor_profile.recordStory(this.state.story_text?this.state.story_text: '',
        this.state.selectedOffice ? this.state.selectedOffice.value : '',
        this.state.selectedDepartment ? this.state.selectedDepartment.value : '',
        this.state.selectedDepartment ? this.state.selectedDepartment.plainLabel : '',
        this.state.selectedLevel ? this.state.selectedLevel.value : '',
        this.state.selectedLevel ? this.state.selectedLevel.plainLabel : '',
        this.state.selectedRelationship ? this.state.selectedRelationship.value : '',
        this.state.other_behavior ? `${this.state.selectedBehavior.value}: ${this.state.other_behavior}`:  (this.state.selectedBehavior ? this.state.selectedBehavior.value : ''),
        this.state.selectedPerspective ? this.state.selectedPerspective.value : '',
        identityValues);

        analyticsTracker.trackEvent({
          category: 'User',
          action: 'Data Entered',
          data: { 
            perspective: this.state.selectedPerspective ? this.state.selectedPerspective.value : null,
            relationship: this.state.selectedRelationship ? this.state.selectedRelationship.value : null,
            department: this.state.selectedDepartment ? this.state.selectedDepartment.value : null,
            office: this.state.selectedOffice ? true : null,
            level: this.state.selectedLevel ? this.state.selectedLevel.value : null,
            behavior: this.state.selectedBehavior ? this.state.selectedBehavior.value : null,
            otherBehavior: this.state.other_behavior ? this.state.other_behavior : '',
            identityValues 
          }
        });

      },this.saveDelay);
      
    
        
    }
  }

  render() {
    return (<div className="page__container">
      <ScrollToTopOnMount/>
        <MenuNavBar className="page__navbar" selected={this.props.currentsection} location={this.props.location} hasVisitorStoryData={this.state.hasVisitorStoryData} toggleModal={this.checkIfShowModal} />
        <Jumbotron className="short__background" fluid>
          <Container className="medium__background_hero" fluid>
            <Col xs="12" sm={{size:9, offset: 1}} md={{ size: 10, offset: 1 }} lg={{size: 10, offset: 1}} xl={{size: 10, offset: 1}}>
              <h1>
                  What brings you here today?
              </h1>
              <h4 className="hero__subtitle">Confidentially unpack your experience so we can guide you to the right resources and tools.</h4>
            </Col>
          </Container>
          <Container className="short__background_content" fluid>
          <Row className="short__background_content_resources">
            <Col xs="12" sm={{ size: 10, offset: 1 }}>
              <ContentContainer cssClass="storyinput__data_container" xs="12" sm={{size:10, offset:1}} md={{size:8, offset:1}} lg={{size:7, offset:1}} xl={{size:6, offset:1}}>
                <StoryInputStepTwo storyData={this.props.storyData} updateText={this.updateText} setStoryData={this.setStoryData} />
                <StoryInputStepOne storyData={this.props.storyData} 
                  updateProps={this.updateState} 
                  toggleModal={this.checkIfShowModal} 
                  modalUrl={this.modalUrl}
                  saveStory={this.saveStory}
                  hasVisitorStoryData={this.state.hasVisitorStoryData}
                  setStoryData={this.setStoryData} />
              </ContentContainer>
            </Col>
          </Row>
        </Container>
        </Jumbotron>
              <Modal isOpen={this.state.modal} toggle={this.toggleModal} className="headless-modal modal-storyinput">
              {this.state.modalBody? this.state.modalBody:<>        
    <ModalBody>
      <p><strong style={{fontWeight: 700}}>It looks like you are about to move on without telling us what brought you here today...</strong></p>
      <p>Here’s why it matters:</p>
      <ul>
        <li><h2>Speak Your Mind Without Judgement</h2><p>Unpacking an uncomfortable situation via a confidential sounding board can be a positive way to release pent-up emotions. It will help you uncover what’s impacting you underneath the surface.</p></li>
        <li><h2>Reflect On What You Want</h2><p>Writing out your thoughts - whether describing a conflict or expressing what you want to learn - can help you analyze and get what you really want out of your visit to tEQuitable.</p></li>
      </ul>
    </ModalBody>
      <Col xs="12" className="text-center">
        <Button className="button__primary btn-block" onClick={this.toggleModal}>GO BACK</Button>
      </Col>
      <Col xs="12" className="text-center">
        <Button id="storyinput__leave" className="button__tertiary btn-block" onClick={this.dontSaveStory}>continue</Button>
      </Col>
    </>}
                
              </Modal>
      </div>);
  }

  checkIfShowModal(url){
    if (!this.state.hasVisitorStoryData) {
      url !== undefined ? this.setState({modalUrl: url}): "" ;
      this.toggleModal(url);
    }
  }

  toggleModal(url){
    if (this.state.modal) {
      analyticsTracker.trackEvent({
        category: 'User',
        action: 'Closed "Are You Sure" modal'
      });
    } else {
      if (url === '/solutions/learningModulesSearch?category=') {
        analyticsTracker.trackEvent({
          category: 'User',
          action: 'Opened "Are You Sure" modal',
          source: 'Bottom Story Input',
          continueButtonUrl: url
        });
      } else if (url !== undefined) {
        analyticsTracker.trackEvent({
          category: 'User',
          action: 'Opened "Are You Sure" modal',
          source: 'MenuNavBar',
          continueButtonUrl: url
        })
      } else {
        analyticsTracker.trackEvent({
          category: 'User',
          action: 'Opened "Are You Sure" modal',
          source: 'Bottom Story Input',
          continueButtonUrl: '/outcome'
        });
      }
    }
    this.setState({ modal: !this.state.modal });
  }

  updateState(fields){
    this.setState({...fields, hasVisitorStoryData: true});
  }

  updateText(text){
    this.setState({story_text: text, hasVisitorStoryData: true});
  }

setStoryData(storyData){
    this.setState({hasVisitorStoryData: storyData});
  }

  dontSaveStory(e){
    e.preventDefault();
    const buttonId = e.currentTarget? e.currentTarget.id: "";

    if (this.state.modal) {
      analyticsTracker.trackEvent({
        category: 'User',
        action: 'Confirmed "Are You Sure" modal',
        buttonClicked: buttonId
      });
    }

      analyticsTracker.trackEvent({
        category: 'User',
        action: 'Data NOT Entered'
      });

    const { history } = this.props;

    history.push({
      pathname: this.state.modalUrl
    });
    
    history.replace(this.state.modalUrl);
  }

  saveStory(e){
    e.preventDefault();

    if (this.state.story_text || 
      this.state.selectedOffice ||
      this.state.selectedDepartment || 
      this.state.selectedLevel ||
      this.state.selectedRelationship ||
      this.state.selectedPerspective ||
      this.state.selectedBehavior
    ) {

      

      const identities = this.state.selectedIdentities ? this.state.selectedIdentities.map(identities => identities.value) : null;
    analyticsTracker.trackEvent({
      category: 'User',
      action: 'Data Entered',
      data: { 
        perspective: this.state.selectedPerspective ? this.state.selectedPerspective.value : null,
        relationship: this.state.selectedRelationship ? this.state.selectedRelationship.value : null,
        department: this.state.selectedDepartment ? this.state.selectedDepartment.value : null,
        office: this.state.selectedOffice ? true : null,
        level: this.state.selectedLevel ? this.state.selectedLevel.value : null,
        behavior: this.state.selectedBehavior ? this.state.selectedBehavior.value : null,
        otherBehavior: this.state.other_behavior ? this.state.other_behavior :  null,
        identities 
      }
    });

    
    const identityValues = this.state.selectedIdentities ? this.state.selectedIdentities.map(identities => identities.value) : '';
    
    visitor_profile.recordStory(this.state.story_text,
      this.state.selectedOffice ? this.state.selectedOffice.value : '',
      this.state.selectedDepartment ? this.state.selectedDepartment.value : '',
      this.state.selectedDepartment ? this.state.selectedDepartment.plainLabel : '',
      this.state.selectedLevel ? this.state.selectedLevel.value : '',
      this.state.selectedLevel ? this.state.selectedLevel.plainLabel : '',
      this.state.selectedRelationship ? this.state.selectedRelationship.value : '',
      this.state.other_behavior ? `${this.state.selectedBehavior.value}: ${this.state.other_behavior}`:  (this.state.selectedBehavior ? this.state.selectedBehavior.value : ''),
      this.state.selectedPerspective ? this.state.selectedPerspective.value : '',
      identityValues);

    const { history } = this.props;

    history.push({
      pathname: '/outcome'
      // pathname: '/custom_results'
    });
    } else if(this.state.hasVisitorStoryData){
      const { history } = this.props;
      history.push({
        pathname: '/outcome'
        // pathname: '/custom_results'
      });

    }else {
      this.toggleModal();
    }
  }
}

export default StoryInput;