import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import HeroListContainer from '../../containers/HeroListContainer';
import StorySummaryCard from '../../components/StorySummaryCard';
import FilterControl from '../../components/FilterControl';
import { Row, Col } from 'reactstrap';
import visitor_profile from '../../VisitorProfile';
import identities from '../Identities';
import './StoryList.css';
import analyticsTracker from '../../../analytics/AnalyticsTracker';
import handleExpiredTokens from '../../../utils/jwtErrorHandler';


class StoryList extends Component {

  constructor(props) {
    super(props);

  this.state = { loadedItems : [],
    hasMoreStories : true,
    availableTopics : [],
    selectedIdentityFilters : `${props.location.search.slice(1,props.location.search.search('&'))}`,
    errorMessage : "",
  };
  this.searchResults = [];
  this.increment = 4; 
  this.endIndex = this.increment; 
  this.storiesLength = 0;

    visitor_profile.setLastStoryFilterRoute(props.history.location);
  }

  render() {
    var filters = identities.storyTopics(this.state.availableTopics);
    var items = [];
    for (var i=0; i<this.state.loadedItems.length; i+=1)
    {
      const cardData = this.state.loadedItems[i];
      items.push(
        <Col xs="12" lg="6" key={i}>
          <StorySummaryCard
            tag="a"
            href={"#"}
            key={i}
            onClick={(e) => this.saveFiltersAndGoToStoryRoute(e, cardData)} 
            {...cardData} />
        </Col>
      );
    }

    return (
    <HeroListContainer {...this.props} contentCssClass="short__background_content" title={this.props.title} subtitle={this.props.subtitle} currentsection="outcomes">
      <Row className="short__background_content_resources">
        <Col sm="12" md={{size: 10, offset: 1}}>
          <FilterControl id="storyFilters" title="Filter" filters={filters} selectedFilters={this.state.selectedIdentityFilters} filtersChangedAction={this.identityFiltersChanged.bind(this)} />
          <InfiniteScroll pageStart={0}
              loadMore={this.loadMoreStories.bind(this)}
              hasMore={this.state.hasMoreStories}
              loader={<div className="loader" key={0}>Loading ...</div>}>
            <div className="story__summary_card_list">
              <Row>
                {items}
              </Row>
            </div>
          </InfiniteScroll>
          <div>{this.state.errorMessage}</div>
        </Col>
      </Row>
    </HeroListContainer>
    );
  }

	componentDidMount() {

    // Fetch the search results given the selected filters
    let queryString = this.props.location.search;
    
    this.fetchStories(queryString)
      .then(res => { 
          if (!res.ok) 
            throw Error(res.statusText); 
          else
            return res;
        });

    analyticsTracker.trackEvent({
      category: 'User',
      action: 'Reached Landing Page',
      pathId: this.props.path_id
    });
  
  }

  saveFiltersAndGoToStoryRoute(e, item){
    e.preventDefault();

    const { history } = this.props;

    history.replace({
      pathname: this.props.location.pathname,
      search: this.state.selectedIdentityFilters + "&subtopics="
    });

    visitor_profile.setLastStoryFilterRoute(history.location);

    history.push({
      pathname: this.props.location.pathname + "/" + item.itemID  
    });
  }

  fetchStories(queryString){
    return new Promise((resolve, reject)=> {

    this.setState({loadedItems : []});

    // Fetch available story topics
    fetch(this.props.endpoint.replace('stories', 'topics'),
      {
        method: 'GET',
        headers: {
         'Accept': 'application/json',
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + visitor_profile.getJwt(),
         'tEQ-preauthorized': visitor_profile.getPreauthorized()
        }
      })
      .then(res => {
        if (!res.ok){
          handleExpiredTokens(res);
          throw Error(res.statusText);
        } else
          return res.json();
      })
      .then(res => this.setState({availableTopics : res})
      // Note: no catch clause; if this call fails, we will show all topics -
      // the only downside is the a filter that will result in empty list
    );

    // Fetch stories
    fetch(this.props.endpoint + queryString + "&visitorId=" + visitor_profile.getId(), 
        {
          method: 'GET',
          headers: {
           'Accept': 'application/json',
           'Content-Type': 'application/json',
           'Authorization': 'Bearer ' + visitor_profile.getJwt(),
           'tEQ-preauthorized': visitor_profile.getPreauthorized()
          }
        })
        .then(res => { 
          if (!res.ok){
              handleExpiredTokens(res);
              throw Error(res.statusText);
        } else
            return res;
        })
        .then(res => res.json())
        .then(data => {
          this.storiesLength = data.length;
          for (var i=0; i < data.length; i++) {   
            this.searchResults.push({ itemID : data[i].id, title : data[i].title, topics : data[i].topic, subtopics : data[i].subtopic });
          }
          let items = this.searchResults.slice(0,this.endIndex);
          this.setState({loadedItems : items, hasMoreStories : this.endIndex < this.storiesLength });
          this.endIndex += this.increment;
          if (data.length === 0)
            this.setState({errorMessage : "No stories found"});
          else
            this.setState({errorMessage : ""});

          visitor_profile.recordPath(this.props.path_id, queryString);
        })
      .catch(err => this.setState({errorMessage : "Oops! We were unable to find any stories, please try again or explore other learning modules."}));

    });
  }

  loadMoreStories(page){
    const item = this.searchResults.slice(0, this.endIndex)
    this.setState({loadedItems : item, hasMoreStories : this.endIndex < this.storiesLength});
    this.endIndex += this.increment;
  }

  identityFiltersChanged(domEvent, selectedValues){
    // Reset our searchResults to an empty array
    this.searchResults = [];
    this.endIndex = this.increment;

    // Fetch stories with the given filters 
    if (selectedValues) {
      var topicQueryString = this.createQueryString(selectedValues);
      
      this.setState({selectedIdentityFilters : "topics=" + topicQueryString});

      this.fetchStories('?topics=' + topicQueryString);
    }
  }

  createQueryString(selectedValues){
    var queryString = '';
    for (const value of selectedValues) {
      if (value !== '')
        queryString += value + ',';
    }
    // We have a few stories labeled 'anyone' internally
    // Make sure those show up for all filters besides the empty filter
    // which automatically returns all anyway 
    if (queryString !== '')
      queryString += 'anyone';

    return queryString;
  }


}

export default StoryList; 

