/**
AllOutcomes.js

Container element for links on the AllOutcomes screen
*/

import IconCardsContainer from '../containers/IconCardsContainer';
import solutionsList from '../solutions/SolutionsList';
import feature_flips from '../../config/FeatureFlips';
import visitor_profile from '../VisitorProfile';
import analyticsTracker from '../../analytics/AnalyticsTracker';
import handleExpiredTokens from '../../utils/jwtErrorHandler';

class AllOutcomes extends IconCardsContainer {

  componentDidMount() {
    const hasStory = visitor_profile.getStoryText();
    const items = [
      {
        path_id: "options_validation",
        to:"#/outcome/options_validation",
        solution_title: "Feel Validated",
        solution_text:"Know that what I'm feeling is legitimate",
        icon_src: "/icons/large/icon_large_legitimate.svg",
        featureEnabled: true
      },
      {
        path_id: "options_release",
        to:`#${this.props.match.path}/options_release`,
        solution_title: "Vent!",
        solution_text:"I just want to rage for a minute!",
        icon_src: "/icons/large/icon_large_respond.svg",
        featureEnabled: (feature_flips.isFeatureEnabledForUser('VentNominate', visitor_profile) === true)
      },
      {
        path_id: "solution_learning_modules_search",
        to:"#/solutions/learningModulesSearch?category=",
        solution_title: "Respond",
        solution_text:"Find out how to respond to the situation",
        icon_src: "/icons/large/icon_large_respond.svg",
        featureEnabled: true
      },
      {
        path_id: "options_education",
        to:`#${this.props.match.path}/options_education`,
        solution_title: "Educate",
        solution_text:"Nominate my boss/co-worker for training",
        icon_src: "/icons/large/icon_large_legitimate.svg",
        featureEnabled: (feature_flips.isFeatureEnabledForUser('VentNominate', visitor_profile) === true)
      },
      {
        path_id: "options_managers",
        to:`#${this.props.match.path}/options_managers`,
        solution_title: "Manage Inclusively",
        solution_text:"Learn skills for building effective teams",
        icon_src: "/icons/large/icon_large_respond.svg",
        featureEnabled: false /* disabled for now - WIP */
      },
      solutionsList.solution_hr,
    ];

    if(hasStory){
      this.saveRecommendations();
    }

    this.setState({ 'links' : items });
  }

  saveRecommendations() {
    analyticsTracker.trackEvent({
      category: 'User',
      action: 'Initiated generating custom results',
    });
    return new Promise((resolve, reject) => {
      fetch ('/ai/recommendV3/' + visitor_profile.getId(),
      {
        method: 'GET',
        headers: {
         'Accept': 'application/json',
         'Content-Type': 'application/json',
         'Authorization': 'Bearer ' + visitor_profile.getJwt(),
         'tEQ-preauthorized': visitor_profile.getPreauthorized()
        }
      }).then(res =>{
        handleExpiredTokens(res)
        return res.json()
      })
      .then(data => {
        analyticsTracker.trackEvent({
          category: 'User',
          action: 'Generated custom results',
          data
        });
        resolve(data);
      }).catch( err => reject(err));
    });
  }
}

export default AllOutcomes;
