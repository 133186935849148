import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import {Animated} from "react-animated-css";
import classNames from 'classnames';

import '../Home.css';

class HomeScreenAnimation extends Component {

  state = { indexIn : 0, 
            indexOut: 0,
            ready: false, 
            phrases : [
              {phrase: "I got tough feedback and don’t know what to do next.", style:"homescreen__phrase_1", isVisible: false},
              {phrase: "A teammate disrespects me, and I don’t know how to stop it.", style:"homescreen__phrase_2", isVisible: false},
              {phrase: "A colleague took credit for my work, and I want to speak up.", style:"homescreen__phrase_3", isVisible: false},
              {phrase: "I’m troubled by world events and struggle to separate them at work.", style:"homescreen__phrase_4", isVisible: false},
              {phrase: "I fear my contributions will be forgotten while I’m on leave.", style:"homescreen__phrase_2", isVisible: false},
              {phrase: "How do I let my teammates know how I want to be identified?", style:"homescreen__phrase_5", isVisible: false},
              {phrase: "I spoke up in a meeting, but I felt unheard.", style:"homescreen__phrase_4", isVisible: false},
              {phrase: "I’m nervous about giving my manager feedback.", style:"homescreen__phrase_3", isVisible: false},
              {phrase: "I feel uneasy about my review and worry my job is at risk.", style:"homescreen__phrase_1", isVisible: false},
              {phrase: "I feel singled out on my team and don’t know who to trust.", style:"homescreen__phrase_3", isVisible: false},
              {phrase: "I need feedback to grow, but don’t know how to ask my manager.", style:"homescreen__phrase_2", isVisible: false},
              {phrase: "I feel othered and want more support from my colleagues.", style:"homescreen__phrase_3", isVisible: false},
              {phrase: "I’m excluded from key meetings and unsure how to assert myself.", style:"homescreen__phrase_1", isVisible: false},
              {phrase: "I made a mistake on a project and don’t know how to fix it.", style:"homescreen__phrase_3", isVisible: false},
              {phrase: "I saw colleagues in a tense disagreement; should I get involved?", style:"homescreen__phrase_4", isVisible: false},
              {phrase: "I received a harsh email, and I’m not sure how to reply.", style:"homescreen__phrase_2", isVisible: false},
              {phrase: "I worry our interview process isn’t fair and don’t know who to tell.", style:"homescreen__phrase_2", isVisible: false},
              {phrase: "I want to propose a new idea, but I’m afraid of being shut down.", style:"homescreen__phrase_3", isVisible: false},
              {phrase: "I’m working on a team project and my contributions go unnoticed.", style:"homescreen__phrase_1", isVisible: false},
              {phrase: "The language my organization used made me feel invisible.", style:"homescreen__phrase_3", isVisible: false},
              {phrase: "My co-workers are always ignoring my ideas.", style:"homescreen__phrase_3", isVisible: false},
              {phrase: "I’m being micromanaged and unfairly assigned projects.", style:"homescreen__phrase_5", isVisible: false},
            ]
          };

  constructor(props) {
    super(props);
    this.makeAnimatedPhrases = this.makeAnimatedPhrases.bind(this);
    this.animateNextIn = this.animateNextIn.bind(this);
    this.animateNextOut = this.animateNextOut.bind(this);
  }

  componentDidMount() {
    if (window.innerWidth >= 576)
      setTimeout(this.animateNextIn, 1000);
  }

  animateNextIn() {
    if (!this.state.ready)
      this.setState({ready:true});

    if (this.state.indexIn < this.state.phrases.length) {
      let nextPhrase = this.state.phrases[this.state.indexIn]; 
      nextPhrase.isVisible = true;
    }
  
    this.setState({ indexIn : this.state.indexIn + 1 }); 
    
    if (this.state.indexOut < this.state.phrases.length) {
      setTimeout(this.animateNextOut, 2000);
    }
  }

  animateNextOut() {
    if (this.state.indexOut >= 0 && this.state.indexOut < this.state.phrases.length) {
      let lastPhrase = this.state.phrases[this.state.indexOut];
      lastPhrase.isVisible = false; 
    }

    this.setState({ indexOut : this.state.indexOut + 1 }); 
    
    if (this.state.indexIn < this.state.phrases.length) {
      setTimeout(this.animateNextIn, 250);
    }
  }



  makeAnimatedPhrases() {
    return this.state.phrases.map((item, index) => {
      let positionStyle = "homescreen__animation_pos_" + (index % 4).toString();

      const cssClasses = classNames('homescreen__phrase', positionStyle, item.style);

      return(
        <Animated key={index.toString()} animationIn="fadeIn" animationInDuration={1000} animationOut="fadeOut" animationOutDuration={750} isVisible={item.isVisible}>
          <span className={cssClasses}>{item.phrase}</span>
        </Animated>
      );

    });
  }

  render() {
    const cssClasses = classNames({
      'homescreen__animation_container': true,
      'homescreen__animation_container_initial': !this.state.ready
    });
    return (
      <Row noGutters>
        <Col xs="12">
          <div className={cssClasses}>
            {this.makeAnimatedPhrases()}
          </div>
        </Col>
      </Row>
    );
  }

}

export default HomeScreenAnimation;
