import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";
import visitor_profile from "../VisitorProfile";
import IconCard from "../components/IconCard";
import StorySummaryCard from "../components/StorySummaryCard";
import Loader from "react-loaders";
import analyticsTracker from "../../analytics/AnalyticsTracker";

class CustomResultsList extends React.Component {
  constructor(props) {
    super(props);
    this.makeCards = this.makeCards.bind(this);
    this.state = { links: this.props.links || [] };

    const loader = <Loader type="ball-scale-ripple" active />;
    this.state = {
      links: [{ component: loader }, { component: loader }],
    };
  }

  
  trackActionClick(item, index, content) {
    analyticsTracker.trackEvent({
      category: 'User',
      action: 'Clicked Custom Results Card',
      cardTitle: item,
      cardOrder: index + 1,
      contentCategory: content
    });
  }

  explore() {
    const more = document.getElementById("Target__chev");
    more.scrollIntoView({behavior: "smooth", block: "start"}); 
    analyticsTracker.trackEvent({
      category: 'User',
      action: 'Clicked Custom Results Scroll Chevron' 
    });
  }

  makeCards() {
    const cards = this.state.links;

    return cards.map((item, index) => {
      return (
        <Col
          className="card__list_col"
          key={index.toString()}
          xs="12"
          sm="12"
          md={{ size: 11}}
          lg={{ size: 6}}
        >
          {item.component ? (
            <IconCard tag="div" className="outcome-icon outcome-icon-loader">
              {item.component}
            </IconCard>
          ) : item.hasOwnProperty("slug") ? (
            <IconCard
              tag="a"
              href={`#/solutions/${item.slug}`}
              heading={item.title}
              text={item.subtitle}
              category={item.category}
              onClick={() => { this.trackActionClick(`${item.title}`, index, 'Action Module') }} 
            >
              {item.icon_url ? (
                <img
                  src={item.icon_url}
                  className="img-fluid"
                  alt={item.title}
                />
              ) : (
                ""
              )}
            </IconCard>
          ) : (
            <StorySummaryCard
              tag="a"
              href={`#/solutions/storiesSearch/${item.id}`}
              topics={
                item.hasOwnProperty("topic") && item.topic !== undefined
                  ? item.topic.split(",")
                  : []
                  
              }
              title={item.title}
              onClick={() => { this.trackActionClick(`${item.title}`, index, 'Story') }} 
            />
          )}
        </Col>
      );
    });
  }

  render() {
    return (
      <Row className="card__list justify-content-center">
        <Col className="card__frame" md="10">
          <Row className="justify-content-center">
            <Col>
              <h1>{this.state.explanation ? 'Recommended For You' : 'Recommending...'}</h1>
              <h4>
                {this.state.explanation ? this.state.explanation : 'We are analyzing and finding content that matches your story.'}
              </h4>
            </Col>
          </Row>
          <Row className="justify-content-center">{this.makeCards()}</Row>
         
          <Row>
            <Col className="text-right" id="Target__chev">Powered By AI</Col>
          </Row>
          <Row>
          <Col className="text-center">
            <div onClick={this.explore}>
              <img src="/icons/large/icon_large_down_chevron.svg" alt="Scroll Down" />
            </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  componentDidMount() {
    if (!visitor_profile.getCustomResults()) {
      visitor_profile
        .getRecommendations()
        .then((data) => {
          console.log('data', data);
          this.setState({ explanation: data.explanation, links: data.recommendations });
        })
        .catch((err, two) => {
          console.log("error from recommend content", err, two);
          this.setState({ explanation: '   ', links: [{
            slug: "toughconvos",
            title: "Navigate Difficult Conversations",
            subtitle:"Communicate effectively with colleagues",
            icon_url: "https://cms.tequitable.com/wp-content/uploads/2021/10/icon_large_strategies.svg"
          },{
            slug: "addressing-conflicts-peacefully",
            title: "Addressing Conflicts Peacefully",
            subtitle:"Techniques to communicate openly",
            icon_url: "https://cms.tequitable.com/wp-content/uploads/2024/10/icon_large_nonviolent-comm.svg"
          }]});
          // window.location.replace("/#/outcome")
        });
    } else {
      this.setState({ links: visitor_profile.getCustomResults() });
    }
  }
}

CustomResultsList.defaultProps = {
  path_id: "",
};

CustomResultsList.propTypes = {
  path_id: PropTypes.string.isRequired,
};

export default CustomResultsList;
