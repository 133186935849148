class Identities {

  constructor() {

    this.ANYONE_LABEL = "Anyone";

  // value - used as a tag during story submission & query param for filtering stories
  // labels - displayed in identity chooser during story submission; no label means its not a choosable identity
  // filterLabel - used as story filters and story cards on storieslist page; also used on individual story page
  // filterOrder - used to order filters in the filter list in a subjective order
  this.identitiesMap = [
      // All has no label, it isn't an "identity" we want folks to choose from
      { label : "", filterLabel : "All", filterOrder : 0, value : '' },
      { label : "Age", filterLabel : "Age", filterOrder : 9, value : 'age' },
      // COVID-19 has no label, it isn't an "identity" we want folks to choose from
      { label : "", filterLabel : "Burnout", filterOrder : 6, value : 'burnout' },
      { label : "Disability", filterLabel : "Disability", filterOrder : 8, value : 'disability' },
      { label : "Gender Identity", filterLabel : "Gender", filterOrder : 2, value : 'gender' },
      { label : "Immigration Status", filterLabel : "Immigrant", filterOrder : 5, value : 'immigrant' },
      { label : "Parental Status", filterLabel : "Parent", filterOrder : 4, value : 'parent' },
      { label : "Race", filterLabel : "Race", filterOrder : 1, value : 'race' },
      { label : "Religion", filterLabel : "Religion", filterOrder : 7, value : 'religion' },
      { label : "Sexual Orientation", filterLabel : "LGBTQIAP2S+", filterOrder : 3, value : 'LGBTQIAP2S' },  /* the database thinks + is a space so omit it in the value */
      { label : "Veteran Status", filterLabel : "Veteran", filterOrder : 10, value : 'veteran_status' },
    ];

    this.filtersMap = [
      { label: "All", order: 0, value: "" },
      { label: "Belonging", order: 1, value: "belonging" },
      { label: "Respect", order: 2, value: "respect" },
      { label: "Communication", order: 3, value: "communication" },
      { label: "Team Dynamics", order: 4, value: "team_dynamics" },
      { label: "Recognition", order: 5, value: "recognition" },
      { label: "Well-Being", order: 6, value: "wellbeing" },
      { label: "Leadership", order: 7, value: "leadership" },
      { label: "Advocacy", order: 8, value: "advocacy" },
      { label: "Burnout", order: 9, value: "burnout" },
    ];

    this.getFilterLabelsForTopics = this.getFilterLabelsForTopics.bind(this);
    this.storyTopics = this.storyTopics.bind(this);
    this.selectableIdentities = this.selectableIdentities.bind(this);
  }

  // Returns a list of identities above, filtered by the passed in availableTopics. It always includes "All" and
  // never includes "Anyone". Items returned are in the order of the list above.
  storyTopics(availableTopics) {
    if (availableTopics && availableTopics.length > 0) {
      // Anyone may appear in the database but we don't want it to be a filter
      return this.filtersMap
          .filter(filter => (filter.value == '' || Array.indexOf(availableTopics, filter.value) > -1))
          .sort((a,b) => a.filterOrder - b.filterOrder);
    }
    // For any reason we got no topics available, just return them all
    return this.filtersMap;
  }

  // Returns the list of identities above that have label attributes. Items returned are in the order of the list above.
  selectableIdentities() {
    return this.identitiesMap.filter(identity => identity.label);
  }

  // Returns an array of filterLabels matching the passed in topics. Items returned are in the order of the passed in topics.
  getFilterLabelsForTopics(topics) {
    if (topics) {
      return topics.reduce((topicLabels, topic) => {
          var identity = this.filtersMap.find(filter => filter.value == topic);
          // In case a story with a topic that isn't in the list above is found in the database
          if (identity)
            topicLabels.push(identity.label);
          else if (topic == "anyone")
            topicLabels.push(this.ANYONE_LABEL);
          return topicLabels;
        }, []);
    }
    return [];
  }
}

const identities = new Identities();

export default identities; 