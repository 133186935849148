
class LearningModules{
    constructor(){

this.moduleFilters = [
    {label : "All", filterOrder : 0, value : '' },
    {label : "Communication", filterOrder : 2, value : "communication"},
    {label : "Culture", filterOrder : 1, value : "culture"},
    {label : "Leadership",filterOrder : 3, value : "management"}
]


    }

getLearningModulesFilters(){
   return this.moduleFilters;
}


}

const learningModules = new LearningModules();
export default learningModules;