import React, { Component } from "react";
import { ListGroupItem, Badge } from "reactstrap";
import classNames from "classnames";
import "./css/IconCard.css";

class IconCard extends Component {
  // icon (children), heading, text

  render() {
    const cssClasses = classNames(
      "iconcard__container topic-" + this.props.topics,
      this.props.className
    );

	const moduleLabelMap = {
		"communication":"Communication",
		"culture":"Culture",
		"management":"Leadership"
	};

	const topics = this.props.hasOwnProperty("category") && (this.props.category !== undefined && this.props.category !== "") ? this.props.category.split(",") : [];

	return (
      <ListGroupItem {...this.props} className={cssClasses}>
        <div className="media">
          <div className="iconcard__icon align-self-center" aria-hidden="true">
            {this.props.children}
          </div>
          <div className="media-body">
            <h2 className="iconcard__title">{this.props.heading}</h2>
            <span className="iconcard__text">{this.props.text}</span>
          </div>
        </div>
        {topics?.length > 0 && (
          <div className="iconcard__pills">
            {topics?.map((badgeitem, index) => (
              <Badge className="topicBadge" key={index} pill>
                {moduleLabelMap[badgeitem]}
              </Badge>
            ))}
          </div>
        )}
      </ListGroupItem>
    );
  }
}

export default IconCard;