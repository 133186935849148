import React, { Component } from 'react';
import MiniIconCard from './components/MiniIconCard';
import { Row } from 'reactstrap';
import './MenuNavBar.css';
import OutcomesIcon from './components/icon_comonents/outcomesIcon';
import FaqIcon from './components/icon_comonents/faqIcon';
import TalkIcon from './components/icon_comonents/talkIcon'
import MenuIcon from './components/menuIcon';
class MenuNavBar extends Component {

  render () {
    return (
      <nav {...this.props}>
        <Row className="menu__navbar">
          {(this.props.location !== undefined && (!this.props.hasVisitorStoryData)) ?
            (<MiniIconCard className="menu__navitem" tag="a" href="" url='/outcome' location={this.props.location} toggle="true" toggleModal={this.props.toggleModal} label="Outcomes" selected={this.props.selected === 'outcomes'}>
            <span className= "menu__icon" ><OutcomesIcon class={this.props.selected === 'outcomes'? "icon_color icon_hover":"icon_hover" }/></span>
            </MiniIconCard>): this.props.hasVisitorStoryData ?
                (<MiniIconCard className="menu__navitem" tag="a" href="/outcome" toggle="false" label="Outcomes" selected={this.props.selected === 'outcomes'}>
                  <span className= "menu__icon" ><OutcomesIcon class={this.props.selected === 'outcomes'? "icon_color icon_hover":"icon_hover" }/></span>
                </MiniIconCard>) :
                (<MiniIconCard className="menu__navitem" tag="a" href="/outcome" toggle="false" label="Outcomes" selected={this.props.selected === 'outcomes'}>
                  <span className= "menu__icon" ><OutcomesIcon class={this.props.selected === 'outcomes'? "icon_color icon_hover":"icon_hover" }/></span>
                </MiniIconCard>)
            }
          
          {(this.props.location !== undefined)&&(!this.props.hasVisitorStoryData) ?
          (<MiniIconCard className="menu__navitem" tag="a" href="" url='/outcome/schedule' location={this.props.location} toggle="true" toggleModal={this.props.toggleModal} label="Talk" selected={this.props.selected === 'talk'}>
            <span className= "menu__icon"><TalkIcon class={this.props.selected === 'talk'? "icon_color icon_hover":"icon_hover" }/></span> 
          </MiniIconCard>):
          (<MiniIconCard className="menu__navitem" tag="a" href="/outcome/schedule" toggle="false" label="Talk" selected={this.props.selected === 'talk'}>
            <span className= "menu__icon"><TalkIcon class={this.props.selected === 'talk'? "icon_color icon_hover":"icon_hover" }/></span>
          </MiniIconCard>)}

          <MiniIconCard className="menu__navitem menu__navitem_faq" tag="a" href="/faq" label="FAQ" selected={this.props.selected === 'faq'}>
            <span className= "menu__icon" ><FaqIcon class={this.props.selected === 'faq'? "icon_color icon_hover":"icon_hover" }/></span>
          </MiniIconCard>

          <MiniIconCard className="menu__navitem menu__navitem_more" tag="a" href="/about" label="More" selected={this.props.selected === 'about'}>
          <span className= "menu__icon" ><MenuIcon class={"icon_hover"}/></span>
          </MiniIconCard>
        </Row>
      </nav>
    );
  }
}

export default MenuNavBar;
