import React, { Component } from 'react';
import { Row, Col, Collapse, Card, CardBody } from 'reactstrap';
import ContentContainer from './containers/ContentContainer';
import HeroListContainer from './containers/HeroListContainer';
import visitor_profile from './VisitorProfile';
import classNames from 'classnames';
import './FAQInteractive.css';

class FAQInteractive extends Component {
  constructor(props) {
    super(props);
    
    this.makeCards = this.makeCards.bind(this);
    this.toggle = this.toggle.bind(this);
    this.state = {'sections': [
      {
        section_title: "What is tEQuitable?",
        section_text:`<p>tEQuitable is a safe place to get advice when you are feeling uncomfortable in the workplace. tEQuitable allows you to privately bring issues forward, acquire new skills, and get guidance on how to address difficult situations at work.</p><p>tEQuitable is an independent, confidential, impartial and off-the-record resource where you can:</p><p><ul><li>Strategize — develop an action plan to best address your situation</li><li>Learn — access tools for healthy and open communication</li><li>Talk — schedule an appointment with a professional Ombuds</li><li>Read — workplace experiences that may be just like yours</li></ul></p><p>tEQuitable’s mission is to help your ${visitor_profile.getOrgType()} create a safe, inclusive and equitable workplace.</p>`,
        open : false,
        iconClassName : "faq__img_closed"
      },
      {
        section_title: "Who is tEQuitable for?",
        section_text:"<p>We encourage all employees to use tEQuitable whether you've had something happen to you, you've seen something happen to someone else, or you are a manager, ally, or advocate looking to learn how to be an inclusive and equitable leader or teammate.</p>",
        open : false,
        iconClassName : "faq__img_closed"
      },
      {
        section_title: "What is an Ombuds?",
        section_text:`<p>Ombuds are trained professionals that help employees figure out how to handle workplace conflict. They are independent, confidential, impartial and off-the-record. Ombuds offer conflict-resolution coaching, education, and consultation with a goal of empowering individuals to handle issues directly themselves. They do not advocate for individuals, groups or entities, but rather for the principles of fairness and equity. They do not play a role in formal processes, investigate problems brought to their attention, or represent any side in a dispute.</p>`,
        open : false,
        iconClassName : "faq__img_closed"
      },
      {
        section_title: "Can I use tEQuitable if I'm not sure what I experienced was really discrimination, or harassment?",
        section_text:"<p>Yes. If what you experienced felt inappropriate or made you uncomfortable, tEQuitable can help. Harassment and discrimination can range from subtle, insidious slights to overt and severe incidents. tEQuitable is a great resource for getting in front of issues like microaggressions and preventing them from becoming bigger issues.</p>",
        open : false,
        iconClassName : "faq__img_closed"
      },
      {
        section_title: "How is my information protected?",
        section_text:"<p>Your privacy is our priority. All of your participation in the platform is confidential and cannot be tracked back to you.</p><p><ol><li>We do not record any personally identifiable information</li><li>We de-identify any information you give us</li><li>We only report trends based on aggregated and anonymized data, not individual incidents</li><li>We use industry-standard encryption for all communications between your web client and our servers</li></ol></p>",
        open : false,
        iconClassName : "faq__img_closed"
      },
      {
        section_title: `How does using tEQuitable help my ${visitor_profile.getOrgType()}?`,
        section_text:`<p>tEQuitable aggregates and anonymizes behavioral trends so your ${visitor_profile.getOrgType()} can get in front of issues before they escalate. Trend data is not linked to any sole individual.</p><p>Using tEQuitable is like voting with your feet. The more information you provide tEQuitable, the better we can guide your ${visitor_profile.getOrgType()} to focus on areas that need improvement.</p>`,
        open : false,
        iconClassName : "faq__img_closed"
      },
      {
        section_title: "Why do I enter my work email?",
        section_text: `<p>We verify that you are a current employee because tEQuitable is a customized instance with content and information specific to your ${visitor_profile.getOrgType()}.</p><p>During the verification process we do not record your email address. We use a secure third-party service to verify your ${visitor_profile.getOrgType()}'s email domain and send you a secure one-time link to access the platform. Once you click the one-time link and come back into tEQuitable, we do not retain or associate the email address with your usage of the service.</p>`,
        open : false,
        iconClassName : "faq__img_closed"
      },
      {
        section_title: "Why do I enter my cell phone number?",
        section_text: `<p>During the verification process, we do not record your mobile number. We use a secure third-party service to verify you are a human and not a bot and send you a secure one-time code via SMS to access the platform. Once you input the 6-digit code and come back into tEQuitable, we do not retain or associate the mobile number with your usage of the service.</p>`,
        open : false,
        iconClassName : "faq__img_closed"
      },
      {
        section_title: "If I make an appointment, who will I talk with? ",
        section_text:"<p>The person you talk with by phone is a trained Organizational Ombuds. They serve as a neutral party and provide you with conflict resolution and problem-solving resources. They do not advocate for individuals, groups or entities, but rather for the principles of fairness and equity. They do not play a role in formal processes, investigate problems brought to their attention, or represent any side in a dispute.</p><p>Ombuds are independent, confidential, impartial, and off-the-record.</p>",
        open : false,
        iconClassName : "faq__img_closed"
      },
      {
        section_title: "How can I get answers to a question that wasn't on this list?",
        section_text:"<p>We love feedback and questions! You may contact us through the Feedback link in the app (no email address required), or you can email us anytime at <a href=\"mailto:feedback@tequitable.com\">feedback@tequitable.com</a>. </p>",
        open : false,
        iconClassName : "faq__img_closed"
      },
    ]};  
  }

  componentDidMount() {
    if (sessionStorage.getItem('v')) {
      visitor_profile.recordPath(this.props.path_id);
    }
  }

  toggle(e) {
    let sections = this.state.sections; 
    if (!e.target.id) // If they clicked on the span
      e.target = e.target.parentElement;
    const id = e.target.id;
    const index = id.slice(id.lastIndexOf('_')+1);
    sections[index].open = !sections[index].open;
    this.setState({ sections: sections });
  }

  
  makeCards() {
    return this.state.sections.map((item, index) => {
      const divid = "FAQdiv__" + index.toString();
      const buttonid = "FAQbutton__" + index.toString();
      const imgid = "FAQimg__" + index.toString();
      const collapseid = "FAQcollapse__" + index.toString();

      const cssClassesHeader = classNames({
        'faq__section_header': true,
        'faq__section_header_open': item.open
      });

      const cssClassesIcon = classNames({
        'faq__img_closed': true,
        'faq__img_open': item.open
      });

      return (
        <Row key={divid}>
          <Col xs="12" sm={{size:10, offset: 1}}>
          <div id={buttonid} className={cssClassesHeader} onClick={this.toggle}>
            <img id={imgid} className={cssClassesIcon} src="/icons/large/icon_large_faq.svg" alt={"Read " + item.section_title} />
            <span>{item.section_title}</span>
          </div>
          <Collapse id={collapseid} isOpen={this.state.sections[index].open}>
            <Card className="faq__content learning__module_section">
              <CardBody dangerouslySetInnerHTML={{__html: item.section_text}}></CardBody>
            </Card>
          </Collapse>
          </Col>
        </Row>
      );
    });
  }
  
  render() {
    return ( 
      <HeroListContainer {...this.props} contentCssClass="short__background_content learning__module_border" title="Tell Me More" subtitle="Learn how tEQuitable can help and what to expect from the process" currentsection={this.props.selected}>
        <Row className="short__background_content_resources">
          <Col xs="12" sm={{ size: 10, offset: 1 }} >
            <ContentContainer cssClass="learning__module" xs="12" sm={{ size: 10, offset: 1 }}>
              {this.makeCards()}
              <div className="empty__header">&nbsp;</div>
              <div className="empty__header">&nbsp;</div>
            </ContentContainer>
          </Col>
        </Row>
      </HeroListContainer>
      );
  }
}

export default FAQInteractive;

