/**
 * FilterControl.js
 * Form control for showing and selecting filters
 * @param {string} cssClass - Additional css class names. Optional. 
 * @param {string} title - What to ask the user. This also functions as the form control label.  Required.
 * @param {array} filters - An array of objects {label: string, value: number}
 * @param {string} id - Value for the `id` attribute. Required.
 * @param {string} name - Value for the `name` attribute. Optional. Will default to controlId
 * @param {func} filtersChangedAction - Callback for when filters change 
 */ 

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col,Row } from 'reactstrap';
import analyticsTracker from '../../analytics/AnalyticsTracker';

import "./css/FilterControl.css";

class FilterControl extends Component {

  constructor(props) {
    super(props);

    this.controlName = this.props.name || this.props.id;
    this.makeFilters = this.makeFilters.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.getSelectedInputValues = this.getSelectedInputValues.bind(this);
    this.setAllCheck = this.setAllCheck.bind(this);
    this.isSelected = this.isSelected.bind(this);
    this.sendSelectedFilterValuesToGA = this.sendSelectedFilterValuesToGA.bind(this);

  }

  changeHandler(domEvent) {    
    domEvent.persist();

    let selectedValues = [];

    // If they clicked 'All' then uncheck everything else 
    if (domEvent.target.value === '') {
      for (var i=0; i<this.props.filters.length; i++)
      {
        const id = `${this.controlName}__${this.props.filters[i].value}`;
        let element = document.getElementById(id);
        element.checked = false; 
      }

      this.setAllCheck(true);
      selectedValues = [];
    }
    else {
      selectedValues = this.getSelectedInputValues();
      // If something is checked besides 'All' then uncheck 'All'
      if (selectedValues && selectedValues.length >= 1) {
        this.setAllCheck(false); 
      }
      // If nothing else is checked, then check 'All'
      else if (!selectedValues || selectedValues.length === 0) {
        this.setAllCheck(true); 
      }
    }

    this.props.filtersChangedAction(domEvent, selectedValues);
    this.sendSelectedFilterValuesToGA(selectedValues);
  }

  makeFilters(filters) {
    const inputs = filters.map( (filter, index) => {
      const id = `${this.controlName}__${filter.value}`;
      return (
        <p key={index.toString()}>
          <input type="checkbox" name={this.controlName} id={id} value={filter.value} checked={this.isSelected(filter.value)} />
          <label htmlFor={id}>{filter.label}</label>
        </p>
      )
    });
    return inputs;
  }

  getSelectedInputValues()
  {
    let selectedItems = [];
    for (var i=0; i<this.props.filters.length; i++)
    {
      const id = `${this.controlName}__${this.props.filters[i].value}`;
      let element = document.getElementById(id);
      if (element.checked && this.props.filters[i].value !== "")
        selectedItems.push(this.props.filters[i].value);
    }
    return selectedItems; 
  }

  isSelected(value) {
    if (value === '' && (this.props.selectedFilters === 'topics=' || this.props.selectedFilters === 'category=')){
      return true; 
    }
    else if (value !== '' && this.props.selectedFilters.search(value) >= 0){
      return true; 
    }
    else
      return false;
  }

  setAllCheck(checked)
  {
    const id = `${this.controlName}__`;
    let element = document.getElementById(id);
    if (element)
      element.checked = checked;
  }

  sendSelectedFilterValuesToGA(activeFilters) {
    analyticsTracker.trackEvent({
      category: 'User',
      action: `Selected ${this.props.id}`,
      label: `${activeFilters}`,
      filter: this.props.id
    });
  }

  render() {
    const inp = this.makeFilters(this.props.filters);

    return (
      <fieldset className="filter__controls" onChange={this.changeHandler}>
        <Row>
        <Col xs="12" sm={{size:10, offset:1}}>
          <div className="filter__controls_inputs">
            {inp}
          </div>
        </Col>
        </Row>
      </fieldset>
    );
  }

}

FilterControl.defaultProps = {
  title: 'Filter',
  filters: [],
  selectedFilters: '',
  id: '',
  filtersChangedAction: null
};

  

FilterControl.propTypes = {
  cssClass: PropTypes.string,
  title: PropTypes.string.isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })).isRequired,
  selectedFilters: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  filtersChangedAction: PropTypes.func
};

export default FilterControl;
