import React, { Component } from 'react';
import visitor_profile from './VisitorProfile';
import analyticsTracker from '../analytics/AnalyticsTracker';
import { Row, Col, Input } from 'reactstrap';
import './StoryInput.css';

class StoryInputStepTwo extends Component
{constructor(props) {
  super(props);
  this.state = {
    story_text : "",
    showThankyou: false,
    sessionStory : false,
    sessionDropDowns: false
  }

  this.saveStory = this.saveStory.bind(this)
  this.handleStoryChange = this.handleStoryChange.bind(this)
  this.recordStory = this.recordStory.bind(this)
  this.storyEdited = this.storyEdited.bind(this);
}

componentDidMount() { 
  const storyInput = visitor_profile.getStoryText();
  const storyDropDowns = visitor_profile.getStoryDropDowns();

  if(storyInput){
    this.props.setStoryData(true)
    this.setState({ story_text: storyInput, sessionStory: true, showThankyou: true });
  }
  if(storyDropDowns){
    this.setState({ sessionDropDowns: true });
  }

}


  componentWillUnmount() {
    // Record data from step 1 (if we have data) but not if we've already hit Submit to record our story
    if (this.props.storyData.dataEntered === true && this.storyEdited() === false) {
      analyticsTracker.trackEvent({
        category: 'User',
        action: 'Story NOT Entered'
      });

    } else {
      visitor_profile.setStoryText(this.state.story_text)
      this.saveStory();
    }
  }
  
  handleStoryChange(e){
    const inputValue = e.target.value
    this.setState({story_text: inputValue, showThankyou: inputValue.length > 0, sessionStory: inputValue.length > 0});
    if(e.target.value == "" && this.state.sessionStory && this.state.sessionDropDowns == false){
      this.props.updateText(e.target.value)
      this.props.setStoryData(false)
      this.setState({sessionStory: false});
    } else {
      this.props.setStoryData(true);
    }
  }

  render() {
    return (
      
              <Row>
              <Col xs="12" sm={{size:10, offset:1}} md={{size:10, offset:1}} xl={{size:10, offset:1}}>
                <p><i><strong>This is not a formal report.</strong> It doesn’t go to management. Whatever you share is confidential and can't be tracked back to you. Your workplace will never receive personal information.</i></p>
                  <Input className="storyinput__input"
                    type="textarea" 
                    name="text" 
                    onChange={this.handleStoryChange} 
                    onBlur={this.saveStory}
                    value={this.state.story_text}
                    placeholder="What brings you here today? Want to learn more about a specific topic? Did something happen at work? Tell us in your own words..."
                  />
                  
                    <p className={'authentication__info ' + (this.state.showThankyou ? 'collapse show' : 'collapse')}><em>Thanks for sharing your experience.</em></p>
                </Col>
              </Row>
      );
  }


  storyEdited() {
    return this.state.story_text && this.state.story_text != '';
  }

  recordStory() {
    this.props.updateText(this.state.story_text);

    const identityValues = this.props.storyData.identities ? this.props.storyData.identities.map(identities => identities.value) : '';

    if(!this.state.story_text.trim()&& 
      !this.props.storyData.office.value &&
      !this.props.storyData.department.value &&
      !this.props.storyData.department.label &&
      !this.props.storyData.level.value &&
      !this.props.storyData.level.label &&
      !this.props.storyData.relationship.value &&
      !this.props.storyData.other_behavior ? `${this.props.storyData.behavior.value}: ${this.props.storyData.other_behavior}` : this.props.storyData.behavior.value &&
      !this.props.storyData.perspective.value &&
      !identityValues){
      console.log(`all story data is empty`)
      return;

    }else{

      analyticsTracker.trackEvent({
        category: 'User',
        action: 'Story Entered'
      });

      visitor_profile.recordStory(this.state.story_text, 
        this.props.storyData.office.value,
        this.props.storyData.department.value,
        this.props.storyData.department.label,
        this.props.storyData.level.value,
        this.props.storyData.level.label,
        this.props.storyData.relationship.value,
        this.props.storyData.other_behavior ? `${this.props.storyData.behavior.value}: ${this.props.storyData.other_behavior}` : this.props.storyData.behavior.value,
        this.props.storyData.perspective.value,
        identityValues);
    }
  }

  saveStory(e) {
    if (e) {
      e.preventDefault();
    }

    if (this.storyEdited()) {

      this.setState({showThankyou: true});

      this.recordStory();
    }
  }

  goBack(e){
    e.preventDefault(); 

    const { history } = this.props;

    // Go back one page 
    history.go(-1);
  }
}

export default StoryInputStepTwo;


